import React from "react";
import {useNavigate} from "react-router-dom";

function About() {
    const navigate = useNavigate();
    const handleBack = (e) => {
        e.preventDefault();
        navigate('/');
    };
    return (
        <>
            <main>
                <section className="section-marketing">
                    <div className="container">
                        <div className="description-section">
                            <h3 className={`notranslate`}>
                                <a className="float-start" href={'!#'} onClick={handleBack}>
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                </a> &nbsp;&nbsp; About Information</h3>
                            <div className="actronq">
                                <p>ActronQRWayfind TM</p>
                                <p>Version 1.0</p>
                            </div>
                            <div className="designer">
                                <p>System designer</p>
                                <p>Actron Control d.o.o</p>
                                <p><strong>Website: </strong>www.actroncontrol.com
                                </p>

                                <p>Contact: info@actroncontrol.com</p>
                            </div>
                            <div className="partner-block">
                                <p><b>Application partner</b></p>
                                <p> Partner Ltd.</p>
                                <p><strong>Website: </strong>www.partner.com</p>
                                <p> Contact: info@partner.com"</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default About;