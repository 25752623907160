export class ConfigDB {
  static data = {
    app_name: "Actron",
    api_url: "https://actron.playcrazzy.com/api/v1/",
    //api_url: 'http://localhost/my/actron-laravel/api/v1/',
    base_url: "https://actronqr.com/",
    is_json: true,
    dataTableLength: [10, 25, 75, 100],
    fev_storage: "actron_fev",
    store_storage: "actron_store",
    origin_storage: "actron_origin",
    pathlevel: "path_level",
    is_mobility: "is_mobility",
    icon_height: "55",
    icon_width: "15",
    levelList: [
      { id: 1, name: "garageFloor" },
      { id: 2, name: "groundFloor" },
      { id: 3, name: "firstFloor" },
      { id: 4, name: "secondFloor" },
      { id: 5, name: "thirdFloor" },
    ],
    levelListText: [
      { id: 1, name: "Garage" },
      { id: 2, name: "Ground Floor" },
      { id: 3, name: "1st Floor" },
      { id: 4, name: "2nd Floor" },
      { id: 5, name: "3rd Floor" },
    ],
      client_store: {
          a: "Main Entrance",
          start_level: 2,
          b: "",
          search_area: false,
          scanner: false,
          level: 2,
          meter_value: "",
          qr_type: "",
          origin: "",
          min_value: "",
          info_raw: "Select Destination (B)",
          main_category: false,
          client_name: "",
          clientData: {},
          isInsideCall: false,
          getAboutInfo:[],
      }
  };
}

export default ConfigDB;
