import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Utils from "../utils";

const name = "home";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

export const homeActions = { ...slice.actions, ...extraActions };
export const homeReducer = slice.reducer;

function createInitialState() {
  let data = Utils.getClientDetail();
  return {
    value: data
  };
}

function createReducers() {
  return {
    setHome,
  };

  function setHome(state, action) {
    state.value = action.payload;
  }
}

function createExtraActions() {
  return {
    home: home(),
  };

  function home() {
    return createAsyncThunk(`${name}/load`, function (arg, { dispatch }) {
      dispatch(homeActions.setHome(arg));
    });
  }
}
