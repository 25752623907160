import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  floorActions,
  homeActions,
  languageActions,
  loaderActions,
  tabActions,
} from "../../store";
import {
  getLocationDetails,
  getLocationTextDetails,
} from "../../services/Common";
import Utils from "../../utils";
import shareIcon from "../../assets/images/share-icon.svg";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";
import ConfigDB from "../../config";

function Details() {
  const shareUrl = ConfigDB.data.base_url;
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  let Client = queryParams.get("Client");
  if (Client == "" || Client == null) {
    Client = localStorage.getItem("ClientName");
  } else {
    localStorage.setItem("ClientName", Client);
  }

  const homeData = useSelector((x) => x.home.value);
  const tabData = useSelector((x) => x.tab.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const POI1 = queryParams.get("POI1");
  const [locationDetail, setLocationDetail] = useState({});
  const [isFavourite, setIsFavourite] = useState(false);
  const [isShare, setIsShare] = useState(false);

  const ZoomLevels = Utils.detectDevice();


  useEffect(() => {
    if (homeData.client_name) {
      dispatch(loaderActions.loader(true));
      getLocationDetails({ uuid: uuid, origin: homeData.a })
        .then((response) => {
          setLocationDetail(response.data.data);
          setIsFavourite(Utils.checkCookie(response.data.data.id));
          dispatch(loaderActions.loader(false));
        })
        .catch((err) => {
          dispatch(loaderActions.loader(false));
        });
    }
  }, [uuid, homeData.client_name, dispatch]);

  useEffect(() => {
    if (Client && !homeData.client_name) {
      dispatch(loaderActions.loader(true));
      getLocationTextDetails({ POI1: "", POI2: "", client_name: Client })
        .then((response) => {
          let detail = { ...homeData };
          detail.level = response.data.data.level;
          detail.start_level = response.data.data.level;
          detail.clientData = response.data.data.clientData;
          detail.getAboutInfo = response.data.data.getAboutInfo;
          detail.getInfo = response.data.data.getInfo;

          if (response.data.data.location_a) {
            detail.a = response.data.data.location_a;

            // if (
            //   localStorage.getItem("setA") == null ||
            //   localStorage.getItem("setA") == ""
            // ) {
            // localStorage.setItem("setA", response.data.data.location_a);
            // }

            detail.origin = response.data.data.location_a;
          }
          if (response.data.data.location_b) {
            detail.b = response.data.data.location_b;
          }
          if (Client) {
            detail.client_name = Client;
          }
          dispatch(homeActions.home(detail));
          if (response.data.data.languageList.length > 0) {
            dispatch(languageActions.setLang(response.data.data.languageList));
          }
          if (response.data.data.levelDeffination.length > 0) {
            dispatch(
              floorActions.setFloor(response.data.data.levelDeffination)
            );
          }
        })
        .catch((err) => {
          dispatch(loaderActions.loader(false));
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [Client, homeData, dispatch]);


  const currentZoom = localStorage.getItem("zoomValue");



  const handleSetName = (e) => {
    e.preventDefault();
    let data = { ...homeData };
    if (
      data.a === locationDetail?.location?.name &&
      data.b !== locationDetail?.location?.name &&
      data.b !== ""
    ) {
      data.a = data.b;
    }
    if (data.a !== locationDetail?.location?.name) {
      data.b = locationDetail?.location?.name;
    }
    data.search_area = true;
    dispatch(homeActions.home(data));
    dispatch(tabActions.home({ tab: 4 }));  
    navigate("/", {
      state: {
        currentZoom: currentZoom,
        currentZoomLevel: location?.state?.currentZoomLevel,
      },
    });

  
  };
  const handleBack = (e) => {
    e.preventDefault();
    let data = { ...homeData };
    if (tabData.tab === 2) {
      data.search_area = false;
    } else {
      data.search_area = true;
    }
    dispatch(homeActions.home(data));

    navigate("/", {
      state: { currentZoomLevel: location?.state?.currentZoomLevel },
    });

    // if (iZoom === 1) {
    //   setIZoom(2);
    //   let data = { ...homeData };
    //   let fileName = Utils.getFloorImage(`${floor}_2`);
    //   fetch(fileName)
    //     .then((response) => {
    //       if (!response.ok) {
    //         toast.error("Network response was not ok");
    //       }
    //       return response.text();
    //     })
    //     .then((svgContent) => {
    //       if (document.querySelector(".mallLayout")) {
    //         document
    //           .querySelector(".mallLayout")
    //           .classList.remove("zoom-svg-out");
    //       }
    //       Utils.svgContainer(svgContent);
    //       document
    //         .getElementById(`${floor}_2`)
    //         .addEventListener("click", (e) => mallLayout(e, 2));

    //       Utils.setAllPOI(
    //         data.level,
    //         data.a,
    //         data.b,
    //         data.qr_type,
    //         data.origin,
    //         data.isInsideCall,
    //         floorData
    //       );
    //       navigate("/");
    //     })

    //     .catch((error) => {
    //       toast.error(Utils.getErrorMessage(error));
    //     });
    // } else if (iZoom === 2) {
    //   setIZoom(3);
    //   localStorage.setItem("iZooms", 3);
    //   let data = { ...homeData };
    //   document.getElementById("zoom-out").classList.remove("d-none");
    //   let fileName = Utils.getFloorImage(`${floor}_4`);
    //   fetch(fileName)
    //     .then((response) => {
    //       if (!response.ok) {
    //         toast.error("Network response was not ok");
    //       }
    //       return response.text();
    //     })
    //     .then((svgContent) => {
    //       if (document.querySelector(".mallLayout")) {
    //         document
    //           .querySelector(".mallLayout")
    //           .classList.remove("zoom-svg-out");
    //         // setTimeout(function () {
    //         //   document
    //         //     .querySelector(".mallLayout")
    //         //     .classList.add(`zoom-svg-in`);
    //         // }, 500);
    //       }
    //       Utils.svgContainer(svgContent);
    //       document
    //         .getElementById(`${floor}_4`)
    //         .addEventListener("click", (e) => mallLayout(e, 4));
    //       Utils.setAllPOI(
    //         data.level,
    //         data.a,
    //         data.b,
    //         data.qr_type,
    //         data.origin,
    //         data.isInsideCall,
    //         floorData
    //       );
    //       navigate("/");
    //     })
    //     .catch((error) => {
    //       toast.error(Utils.getErrorMessage(error));
    //     });
    // }
  };
  const handleSetAName = (e) => {
    e.preventDefault();
    let data = { ...homeData };
    if (data.b === locationDetail?.location?.name) {
      data.b = data.a;
    }
    // localStorage.setItem("setA", locationDetail?.location?.name);
    data.a = locationDetail?.location?.name;

    data.start_level = locationDetail?.location?.level;
    data.search_area = true;
    dispatch(homeActions.home(data));

    navigate("/", {
      state: { currentZoomLevel: location?.state?.currentZoomLevel },
    });
    document.querySelector(".mallLayout").classList.remove("zoom-svg-impact");
  };
  const handelFavourite = (e) => {
    if (locationDetail?.location?.id) {
      Utils.manageCookie(locationDetail?.location?.id, e.target.checked);
      setIsFavourite(e.target.checked);
    }
  };
  const handelShare = (e) => {
    e.preventDefault();
    let share = true;
    if (isShare) {
      share = false;
    }
    setIsShare(share);
  };

  return (
    <main>
      <section className="section-marketing">
        <div className="container">
          {locationDetail?.location?.in_list === 2 && (
            <div className="slideshow-bg">
              <img src={locationDetail?.location?.image} alt="..." />
            </div>
          )}
          <div className="images-item-row-3">
            <div className="col-back8">
              <div className="btn-row d-flex">
                <a className="btn btn-back" href={"!#"} onClick={handleBack}>
                  {" "}
                  <i
                    className="fa fa-arrow-left fa-2x"
                    aria-hidden="true"
                  ></i>{" "}
                  Back
                </a>
                <div className="btn-row-next">
                  <NavLink
                    className="btn btn-select-a"
                    to={"/"}
                    onClick={handleSetAName}
                  >
                    Set as Start point
                    <div className="submenu-box-2">
                      <p className="notranslate">A</p>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn btn-select-b"
                    to={"/"}
                    onClick={handleSetName}
                  >
                    Set as Destination
                    <div className="submenu-box-b">
                      <p className="notranslate">B</p>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-back4">
              <div className="add-to-row">
                <div className="icon-col">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      className="chb chb-3"
                      id="chb-3"
                      checked={isFavourite}
                      onChange={handelFavourite}
                    />
                    <label htmlFor={`chb-3`}>Add to Favorites</label>
                  </div>
                </div>
                <div className="share-icon float-end">
                  <a className="share-btn" href="!#" onClick={handelShare}>
                    <img src={shareIcon} alt="share" />
                  </a>
                  {isShare && (
                    <>
                      <WhatsappShareButton
                        url={
                          shareUrl +
                          "?Client=" +
                          homeData.client_name +
                          "&Type=" +
                          1 +
                          "&POI1=" +
                          locationDetail?.originPoi?.p_number +
                          "&POI2=" +
                          locationDetail?.location?.p_number
                        }
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                      <FacebookShareButton
                        url={shareUrl + "?Client=" + homeData.client_name}
                        className=""
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="description-section">
            <h3 className={`notranslate`}>{locationDetail?.location?.name}</h3>
            <p>{locationDetail.location?.description}</p>
            {locationDetail?.location?.website && (
              <div className="link-row">
                <strong>Website:</strong>
                <a href={locationDetail?.location?.website}>
                  {locationDetail?.location?.website}
                </a>
              </div>
            )}
            {locationDetail?.location?.contact && (
              <div className="phone-row">
                <p>
                  Telephone:{" "}
                  {locationDetail?.location?.contact
                    ? locationDetail?.location?.contact
                    : "-"}
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
    </main>
  );
}

export default Details;
